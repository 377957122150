import React from 'react'
import {PhotoSwipeGallery} from 'react-photoswipe';
import 'react-photoswipe/lib/photoswipe.css';
import './gallery.css';

export const getUrlFromImage = (image) => {
  return !!image.childImageSharp ? image.childImageSharp.fluid.src : image;
};

const getThumbnailContent = (item) => {
  return (
    <img src={item.thumbnail} width={item.w} height={item.h} alt="referentie"/>
    // <PreviewCompatibleImage imageInfo={item.custom}/>
  );
};

const options = {
  //http://photoswipe.com/documentation/options.html
  tapToClose: false,
  closeOnScroll: false,
  history: false,
  shareEl: false
};
export default class Gallery extends React.Component {
  constructor(props) {
    super(props);
    const allItems = this.props.images
      .filter(item => item && item.image)
      .map(item => {
        const image = item.image.childImageSharp;
        return {
          src: getUrlFromImage(item.image),
          thumbnail: getUrlFromImage(item.image),
          w: image && image.original ? image.original.width : 800,
          h: image && image.original ? image.original.height : 600,
          title: item && item.text ? item.text : '',
          custom: item
        }
      });
    // console.log('gallery', allItems)

    this.state = {images: allItems};
  }

  render() {
    return (
      <PhotoSwipeGallery className={this.props.small ? 'small' : ''}
        items={this.state.images} 
        options={options}
        thumbnailContent={getThumbnailContent}/>
    )
  }
}
