import React from 'react';
import unified from 'unified'
import remarkParse from 'remark-parse'
import remarkReact from 'remark-react'

import Gallery from './Gallery'

const getHtmlForMd = (text, react) => {
	return unified()
	.use(remarkParse)
	.use(remarkReact, react)
	.processSync(text).result
}

export const getUrlFromImage = (image) => {
	return !!image.childImageSharp ? image.childImageSharp.fluid.src : image;
};

const DienstItem = class extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
		const isEven = this.props.index%2;
		const offet = isEven ? ' offset-md-4' : '';
		const even = isEven ? ' even' : '';
    return (
      <div className="row diensten">
			<div className={'col-md-8' + offet}>
				<div className={'dienst-item' + even}>
					<div className="d-flex flex-row flex-md-nowrap align-items-center mb-4">
						<div className="dienst-item-image" style={{background: `url(${this.props.image ? getUrlFromImage(this.props.image) : ''}) center center / contain no-repeat`}}>						
						</div>
						<h2 className="dienst-item-title">{this.props.title}</h2>
					</div>
					<p>
						{ this.props.text ? getHtmlForMd(this.props.text, React) : ''}
					</p>

					<Gallery images={this.props.referenties} small={true}/>
					<div className="row justify-content-end">
						<a href="#contact" className={'btn btn-dienstitem' + even}>
							<span>Contacteer ons <Arrow color={isEven ? 'white' : '#9D000F'} /></span>
						</a>
						<a href="mailto:elektro.tomdillen@gmail.com?subject=Aanvraag offerte" className={'btn btn-dienstitem' + even}>
							<span>Vraag hier een offerte <Arrow color={isEven ? 'white' : '#9D000F'} /></span>
						</a>
					</div>
				</div>
			</div>
		</div>
    )
  }
}

const Arrow = (props) => (
	<svg version="1.1" className="arrow" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 36.1 25.8" enableBackground="new 0 0 36.1 25.8">
		<g>
			<line fill="none" stroke={props.color} strokeWidth="3" strokeMiterlimit="10" x1="0" y1="12.9" x2="34" y2="12.9"></line>
			<polyline fill="none" stroke={props.color} strokeWidth="3" strokeMiterlimit="10" points="22.2,1.1 34,12.9 22.2,24.7"></polyline>
		</g>
	</svg>
);


export default DienstItem