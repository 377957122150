import React from "react";
// import { Link } from "gatsby";

// import logo from "../img/logo.svg";
// import facebook from "../img/social/facebook.svg";
// import instagram from "../img/social/instagram.svg";
// import twitter from "../img/social/twitter.svg";
// import vimeo from "../img/social/vimeo.svg";

const Footer = class extends React.Component {
  constructor(props) {
    super(props);
    // console.log(props)
  }

  render() {
    return (
      <footer className="lt-footer">
        <div className="container">
          <div className="row pb-5 pt-5">
            <div className="col-md-6">
              <h1 id="contact">Contact</h1>
              <p name="lead">
                {this.props.contactgegevens.text}
                {/* Heb je nog vragen of had je een offerte gewenst? <br />
                Aarzel niet om contact op te nemen! */}
              </p>

              <form
                name="contact"
                method="POST"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
              >
                <input type="hidden" name="bot-field" />
                <input type="hidden" name="form-name" value="contact" />

                <input
                  className="form-control form-control-lg form-control-ltquality"
                  name="name"
                  id="name"
                  type="text"
                  placeholder="Naam"
                />
                <label htmlFor="name">Naam</label>
                <input
                  className="form-control form-control-lg form-control-ltquality"
                  name="email"
                  type="email"
                  id="email"
                  placeholder="E-mailadres"
                />
                <label htmlFor="email">E-mailadres</label>

                <textarea
                  className="form-control form-control-lg form-control-ltquality"
                  name="message"
                  rows="4"
                  cols="50"
                  id="message"
                  placeholder="Bericht"
                ></textarea>
                <label htmlFor="message">Bericht</label>

                <button className="btn btn-ltquality" type="submit">
                  Verzenden
                </button>
              </form>
            </div>
            <div className="col-md-4 offset-md-1 contactgegevens">
              <h4 className="mt-5">Contactgegevens</h4>
              <ul className="list-unstyled text-small">
                <li>
                  <a href={'tel:' + this.props.contactgegevens.telefoon}>{this.props.contactgegevens.telefoon}</a>
                </li>
                <li>
                  <a href={'mailto:' + this.props.contactgegevens.email}>{this.props.contactgegevens.email}</a>
                </li>
                <li>{this.props.contactgegevens.ondernemingsnr}</li>
                <li style={{'marginTop': '10px'}}>
                  <a href="./pdf/privacyverklaring.pdf">Privacyverklaring</a>
                </li>  
              </ul>
              <h4>Adres</h4>
              <ul className="list-unstyled text-small">
                <li>{this.props.adres.straat}</li>
                <li>{this.props.adres.plaats}</li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    );
  }
};

export default Footer;
