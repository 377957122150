import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import Gallery from "../components/Gallery";
import Footer from "../components/Footer";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

import logo from "../../static/img/goed/logo.svg";
import DienstItem from "../components/DienstItem";

//TODO: contact form+text, 

let lastScrollY = 0;
let ticking = false;
let windowWidth = 0;
const headerHeight = 650;
const camionetteWidth = 210;

const IndexPageTemplate = class extends React.Component {
  constructor(props) {
    super(props);
    // console.log('test123', this.props.data.team.image)
    this.state = {
      positionOfCar: -camionetteWidth,
      meerFotoToggle: false
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, true);
  }

  handleScroll = () => {
    lastScrollY = window.scrollY;
    windowWidth = window.innerWidth;

    if (!ticking) {
      window.requestAnimationFrame(() => {
        const p1 = lastScrollY / headerHeight;
        const p2 = (p1 > 1 ? 1 : p1) * (windowWidth + camionetteWidth);
        const position = p2 - camionetteWidth;
        this.setState({positionOfCar: position});
        ticking = false;
      });
   
      ticking = true;
    }
  };

  toggleMeerFoto = () => {
    this.setState({meerFotoToggle: !this.state.meerFotoToggle});
  }


  render() {
    return (
      <div>
        <section className="header">
          <h1 className="page-title">L&T Quality</h1>
          <img id="logo" src={logo} alt="logo" />
          <div id="camionette" style={{ right: this.state.positionOfCar }}></div>
        </section>
        <div className="container">
          <h2 className="page-title">ltquality</h2>
          <div className="mb-4 text-center" id="team">
            <h1>Team</h1>
            <p className="lead">{this.props.data.team.subtitle}</p>
          </div>
          <div className="row mb-5">
            <div className="col-md-6">
              <PreviewCompatibleImage imageInfo={{image: this.props.data.team.image.image}}/>
            </div>
            <div className="col-md-6 pt-3 team-info">
              {this.props.data.team.description}
            </div>
          </div>
          <div className="mb-4 text-center" id="diensten">
            <h1>Diensten</h1>
            <p className="lead">
              {this.props.data.diensten.subtitle}
            </p>
          </div>
          {this.props.data.diensten.diensten.map((item, index) => (
            <DienstItem
              key={index}
              index={index}
              image={item.image}
              title={item.title}
              text={item.body}
              referenties={item.referenties ? item.referenties : []}
            />
          ))}
          <div className="mb-4 mt-5 text-center" id="referenties">
            <h1>Referenties</h1>
          </div>
          <div className="row mb-4" style={{overflow: 'hidden', height: this.state.meerFotoToggle ? 'auto' : '500px'}}>
            <Gallery images={this.props.data.referenties} />
          </div>
          <div className="row mb-5 d-flex justify-content-center foto-button-row">
            <div className="foto-button" onClick={this.toggleMeerFoto}>
              Toon {this.state.meerFotoToggle ? 'minder' : 'meer'}
            </div>
          </div>
        </div>
        <Footer contactgegevens={this.props.data.contactgegevens} adres={this.props.data.adres} />
      </div>
    );
  }
};

// IndexPageTemplate.propTypes = {
//   image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
//   title: PropTypes.string,
//   heading: PropTypes.string,
//   subheading: PropTypes.string,
//   mainpitch: PropTypes.object,
//   description: PropTypes.string,
//   intro: PropTypes.shape({
//     blurbs: PropTypes.array
//   })
// };

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  // console.log(frontmatter)
  return (
    <Layout>
      <IndexPageTemplate data={frontmatter} />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
};

export { IndexPageTemplate };
export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: {templateKey: {eq: "index-page"}}) {
      frontmatter {
        title
        team {
          subtitle
          description
          image {
            alt
            image {
              childImageSharp {
                gatsbyImageData(
                  width: 600
                  quality: 75
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
        diensten {
          subtitle
          diensten {
            title
            image {
              childImageSharp {
                fluid(maxWidth: 180, quality: 60) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            body
            referenties {
              image {
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 60) {
                    ...GatsbyImageSharpFluid
                  }
                  original {
                    width
                    height
                  }
                }
              }
              text
            }
          }
        }
        referenties {
          image {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 60) {
                ...GatsbyImageSharpFluid
              }
              original {
                width
                height
              }
            }
          }
          text
        }
        contactgegevens {
          text
          telefoon
          email
          ondernemingsnr
          facebook
        }
        adres {
          straat
          plaats
        }
      }
    }
  }
`;
